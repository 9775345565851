<template>
	<div @click="openModal" class="btn">
	    <img v-if="getValue == undefined || getValue.trim().length == 0" src="/edit_icon.svg" width="24px">
		<p v-else>{{ getDisplayText }}</p>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import TimeHelper from '@/classes/time_helper.js'
	export default {
		name: 'next-contact',
		props: ['payload', 'additional_data'],
		data(){
			return {
				time_helper: new TimeHelper()
			}
		},
		computed: {
			getValue(){
				return this.payload[this.additional_data.value_path]
			},
			getDisplayText(){
				if(this.additional_data.field_type == 'date_time'){
					return this.time_helper.makeTimeReadable(this.getValue)
				}
				return this.getValue
			}
		},
		methods: {
			openModal(){
				this.createModal({
					component_name: 'FieldForm',
					modal_title: `Edit ${this.$utils.snakeCaseToSentence(this.additional_data.value_path)}`,
					field_name: this.additional_data.value_path,
					value: this.getValue,
					resource_id: this.payload.id,
					resource_type: this.additional_data.resource_type,
					field_type: this.additional_data.field_type
				})
			},
			...mapMutations('modals', ['createModal'])
		}
	}
</script>
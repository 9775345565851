<template>
  <div>
    <div class="operations flex flex_align_center">
      <a
        v-if="show_edit"
        @mouseleave="makeEvent({ event_name: 'edit_leave', event_data: payload })"
        @mouseover="makeEvent({ event_name: 'edit_hover', event_data: payload })"
        @click="makeEvent({ event_name: 'edit_click', event_data: payload})"
        class="edit-btn button is-info"
      >
        Edit
      </a>
      <a
        v-if="show_delete"
        @click="makeEvent({ event_name: 'delete', event_data: payload })"
        class="delete-btn button is-danger"
        >Delete</a
      >
      <div
        class="operation_icon"
        :key="operation.name"
        v-for="operation in operations"
      >
        <div @click="makeEvent({ event_name: operation.name.toLowerCase(), event_data: payload })" v-if="operation.component == undefined">
          <img
            class="operation_icon"
            v-if="operation.icon != undefined"
            :src="operation.icon"
          />
          <a
            v-else
            class="button is-info custom_operation_btn"
          >
            {{ (operation.display_text || operation.name) }}
          </a>
        </div>
        <div v-else>
          <component
            :is="operation.component"
            :additional_data="operation.additional_data"
            :payload="getComponentData(operation.component_data_path)"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPropertyFromPathSafely } from '@/classes/my_utils.js'
import SlotsRouterLink from '@/components/SlotsRouterLink'
export default {
  name: 'operations',
  props: {
    show_edit: {
      type: Boolean,
      default: true
    },
    show_delete: {
      type: Boolean,
      default: true
    },
    operations: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object,
      default: () => {}
    },
    payload: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getComponentData (component_data_path) {
      if (component_data_path == undefined) {
        return this.payload
      }
      return getPropertyFromPathSafely({ context: this.payload, path: component_data_path })
    },
    makeEvent (params) {
      this.$emit('operation_clicked', params)
    }
  },
  components: {
    SlotsRouterLink
  }
}
</script>
<style>
  .operations a, .operations img {
    display: block;
    margin-bottom: 6px;
    margin-right: 6px;
  }
</style>
<style scoped>
  .operations {
    max-width: 170px;
    flex-flow: wrap;
  }
  .operation_icon {
    cursor: pointer;
  }
  .operation_icon img{
    min-width: 30px;
    width: 30px;
  }
</style>

class SlotsHelper {

  getGreenStatuses(){
  	return ['active', 'pending_shuffle_out', 'pending_review', 'expiring', 'pending_remote_position_id']
  }

  getOrangeStatuses(){
  	return ['shuffling_in', 'pending_shuffle_in']
  }

  getRedStatuses(){
  	return ['expired', 'pending_delete', 'deleting']
  }

  getStatuses () {
    return this.getOrangeStatuses().concat(this.getRedStatuses()).concat(this.getGreenStatuses())
  }

  getFiltersOptions () {
    let options = []
    let statuses = this.getStatuses()
    let color = ''
    for (let i = 0; i < statuses.length; i++) {
      let status = statuses[i]
      if (this.getGreenStatuses().includes(status)) {
        color = '🍏'
      }
      if (this.getOrangeStatuses().includes(status)) {
        color = '🍊'
      }
      if (this.getRedStatuses().includes(status)) {
        color = '🔴'
      }
      options.push({
        text: color + ' ' + status,
        value: status
      })
    }
    return options
  }


}

export default SlotsHelper
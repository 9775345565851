<template>
  <div class="full_width">
    <div style="display: table; min-width: 100%;">
      <div class="flex title_container wrap_flex">
        <div class="align_self_flex_end full_width">
          <div class="flex flex_align_center flex_justify_between full_width">
            <div class="flex">
              <p class="page_title">{{title}}</p>
              <sorters :sorters="sorters"></sorters>
              <single-filters :filters="getFiltersByType('single')"></single-filters>
              <date-filters :filters="getFiltersByType('date')"></date-filters>
            </div>
            <div class="flex flex_align_center">
              <div class="flex flex_align_center">
                <a v-for="unique_button in unique_buttons" :key="unique_button.name" @click="$emit(unique_button.name)" class="unique_button button"
                   :class="getCssClasses({}, unique_button)">
                  {{unique_button.display_text}}
                </a>
              </div>
              <div v-if="is_createable" class="unique-button-faded-border-left">
                <a
                  @click="$emit('create')"
                  class="button is-info"
                  >Create</a
                >
              </div>
            </div>
          </div>
          <div class="relative" v-if="response.length == 0" style="padding: 20px 0">
            <div class="loading" v-if="is_loading"></div>
            <p v-else>No Results.</p>
          </div>
        </div>
      </div>
      <multiple-filters :filters="getFiltersByType('multiple')" :class="{ filters: response.length > 0 }"></multiple-filters>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="table" class="table center-aligned-table">
              <tr>
                <th
                  :key="field.name"
                  v-for="field in fields"
                  class="tr_field_name border-bottom-0"
                >
                  {{ field.name }}
                </th>
              </tr>
              <tbody>
                <tr
                  :class="{ odd: index % 2 == 0 }"
                  :key="index"
                  v-for="(row_data, index) in response"
                >
                  <td class="fade_animation" :key="field.value" v-for="field in getFields">
                    <operations v-if="field.type == 'operations'" :show_edit="show_edit" :show_delete="show_delete" :operations="operations" :payload="row_data"
                                @operation_clicked="$emit($event.event_name, $event.event_data)">
                    </operations>
                    <div v-else>
                      <component
                        v-if="typeof field.component == 'string'"
                        :is="field.component"
                        :ref="field.component"
                        :payload="getComponentData(row_data, field.component_data_path)"
                        :additional_data="field.additional_data"
                      ></component>
                      <div v-else :class="{ large_text: field.is_large_text }">
                        <p v-if="field.is_html" v-html="$sanitize(getFieldData(row_data, field))"></p>
                        <p v-else>{{ getFieldData(row_data, field) }}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style="padding: 0 5px 10px 20px;">
              {{ response.length + " Entries" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPropertyFromPathSafely } from '@/classes/my_utils.js'
import TimeHelper from '@/classes/time_helper.js'
import Sorters from '@/components/Sorters'
import SingleFilters from '@/components/SingleFilters'
import MultipleFilters from '@/components/MultipleFilters'
import DateFilters from '@/components/DateFilters'
import Operations from '@/components/Operations'
import LinkEntity from '@/components/LinkEntity'
import Field from '@/components/Field'
import SlotStatus from '@/components/SlotStatus'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    sorters: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    response: {
      type: Array,
      default: () => []
    },
    is_createable: {
      type: Boolean,
      default: true
    },
    show_edit: {
      type: Boolean,
      default: true
    },
    show_delete: {
      type: Boolean,
      default: true
    },
    show_operations: {
      type: Boolean,
      default: true
    },
    operations: {
      type: Array,
      default: () => []
    },
    unique_buttons: {
      type: Array,
      default: () => []
    }
  },
  name: 'datatable',
  data () {
    return {
      time_helper: new TimeHelper(),
      is_loading: true,
    }
  },
  computed: {
    getFields () {
      let fields = this.fields
      if(!this.show_operations){
        return fields 
      }
      fields.unshift({ name: 'Operations', type: 'operations' })
      return fields
    }
  },
  watch: {
    response (response) {
      if (response.length == 0) {
        this.is_loading = false
      }
      this.is_th_searching = false
    }
  },
  created(){
    document.title = 'Stargate - ' + this.title
  },
  methods: {
    getFiltersByType(type){
      return this.filters.filter(filter => filter.type == type)
    },
    getComponentData (row_data, component_data_path) {
      if (component_data_path == undefined) {
        return row_data
      }
      return getPropertyFromPathSafely({ context: row_data, path: component_data_path })
    },
    getCssClasses (row_data, field) {
      if (field.css_classes == undefined) {
        return {}
      }
      let classes = {}
      for (let key in field.css_classes) {
        let method = field.css_classes[key]
        classes[key] = method(row_data)
      }
      return classes
    },
    getFieldData (row_data, field) {
      let field_data = getPropertyFromPathSafely({ context: row_data, path: field.value })
      if (field_data == undefined) {
        return ''
      }
      if (field.is_time) {
        return this.time_helper.makeTimeReadable(field_data)
      }
      return field_data
    }
  },
  components: {
    Sorters,
    SingleFilters,
    Operations,
    MultipleFilters,
    DateFilters,
    LinkEntity,
    Field,
    SlotStatus
  }
}
</script>
<style scoped>
.large_text{
  white-space: pre-line;
}
table {
  border-collapse: separate;
}
.tr_field_name {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.mandatory_question p{
  display: inline-block;
  font-weight: bold;
  border-radius: 30px;
  padding: 10px 30px;
  background-color: orange;
}
.unique_button{
  margin-right: 10px;
}
.loading {
  margin-bottom: 10px;
}
.filters {
  margin-top: 10px;
}
.title_container {
  margin-top: 10px;
}
td {
  max-width: 300px;
  word-wrap: break-word;
}

.fade_animation {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.loading{
  margin-bottom: 30px;
}
.page_title{
  align-self: flex-end;
  margin-right: 25px;
  font-size: 25px;
}
.card{
  padding-left: 0;
  padding-right: 0;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: white;
  border-radius: 6px;
}
.card .card-body + .card-body {
  padding-top: 0;
}
.card-title {
  margin: 0;
}
.card-title {
  margin-top: 10px;
  font-size: 30px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.unique_button{
  border-radius: 4px;
}
.unique_button_right_split{
  max-width: 1px;
  padding: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  margin-right: 10px;
}
</style>

<template>
	<div>
		<router-link :to="{ name: 'slots', query: { supplier_id: payload.id, statuses: 'active' } }">
			<a class="button is-info custom_operation_btn">Slots</a>
		</router-link>
	</div>
</template>
<script>
	export default {
		name: 'slots-router-link',
		props: ['payload']
	}
</script>
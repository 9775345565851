<template>
	<div class="flex">
		<div v-for="filter in filters" style="margin-right: 10px;">
			<p>{{ filter.designator }}</p>
			<date-time @input="updateFilter(filter, $event)" :outlined="true" :date="$route.query[filter.designator]"></date-time>
		</div>
	</div>
</template>
<script>
	import DateTime from '@/components/DateTime'
	export default {
		name: 'date-filters',
		props: ['filters'],
		methods: {
			updateFilter(filter, value){
		      let query = { ...this.$route.query }
		      query[filter.designator] = value == undefined ? undefined : value
		      this.$router.push({ path: this.$route.path, query: query })
			}
		},
		components: {
			DateTime
		}
	}
</script>
<template>
  <div v-if="payload">
    <router-link :to="{ name: simplifiedData.route_name, query: simplifiedData.query }"
                 :class="{ un_clickable: simplifiedData.route_name == undefined }" class="blue_text link-entity">
      <u>{{simplifiedData.display_text}}</u>
    </router-link>
    <div v-if="simplifiedData.components && simplifiedData.show_components" v-for="component in simplifiedData.components">
      <component :is="component" :payload="payload"></component>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import PositionStatsLink from '@/components/PositionStatsLink'
export default {
  name: 'link_entity',
  props: ['payload', 'additional_data'],
  computed: {
    mergedData () {
      return { ...this.payload, ...this.additional_data }
    },
    simplifiedData: function () {
      if (this.mergedData.resource_type == 'Position') {
        return {
          route_name: 'positions',
          modal_name: 'PositionForm',
          query: { env: this.mergedData.env, env_position_id: this.mergedData.env_position_id, show_id: 1 },
          display_text: this.mergedData.title,
          show_components: this.mergedData.show_components == undefined ? true : this.mergedData.show_components,
          components: ['PositionStatsLink']
        }
      }
      return {}
    },
    ...mapGetters('modals', ['isModalOpen', 'findModal'])
  },
  methods: {
    openModal () {
      this.createModal({
        designator: 'link-entity-resource-modal',
        resource_type: this.mergedData.resource_type,
        resource_id: this.mergedData.resource_id,
        component_name: this.simplifiedData.modal_name,
        data: this.mergedData,
        modal_title: this.simplifiedData.display_text,
        is_view_mode: false
      })
    },
    getModal () {
      return this.findModal({ type: 'designator', value: 'link-entity-resource-modal' })
    },
    ...mapMutations('modals', ['removeModal', 'createModal'])
  },
  components: {
    PositionStatsLink
  }
}
</script>
<style scoped>
  .un_clickable{
    pointer-events: none;
  }
  .link-entity{
    color: #3273dc !important;
  }
</style>

import router from '@/router/index.js'
import Settings from '@/classes/settings'
class FiltersHelper {
  constructor () {
    this.route = router.app.$route
    this.settings = new Settings()
  }

  getServerCallFilters (filters) {
    let data = {}
    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (this.route.query[filter.designator] == undefined) {
        continue
      }
      data[filter.designator] = this.route.query[filter.designator]
    }
    return data
  }

  getEnvsAsFilters () {
    let envs = ['Rebase', 'Suitup', 'Piola', 'Bullrun']
    let options = []
    for (let i = 0; i < envs.length; i++) {
      let env = envs[i]
      options.push({
        text: env,
        value: env
      })
    }
    return options
  }

  getOutsourcesAsFilters () {
    let outsources = ['Alljobs', 'Jobmaster', 'Jobnet', 'Drushim', 'Facebook', 'Seo']
    let options = []
    for (let i = 0; i < outsources.length; i++) {
      let outsource = outsources[i]
      options.push({
        text: outsource,
        value: outsource
      })
    }
    return options
  }

  isFilterOptionSelected (filter, option) {
    let url_filter_value = (this.route.query[filter.designator] || '').split(
      ','
    )
    return url_filter_value.includes(option.value + '')
  }
}

export default FiltersHelper

<template>
  <div v-if="sorters && sorters.length > 0" style="align-self: flex-start; margin-right: 10px;">
    <p style="margin-bottom: 3px;">Sort</p>
    <select @change="sort($event.target.value)">
      <option
        :selected="sorter.value == $route.query.sort"
        v-for="sorter in sorters"
        :key="sorter.value"
        :value="sorter.value"
        >{{ sorter.designator }}</option
      >
    </select>
  </div>
</template>
<script>
export default {
  name: 'sorters',
  props: ['sorters'],
  methods: {
    sort (sort) {
      let query = { ...this.$route.query }
      query.sort = sort
      this.$router.push({ path: this.$route.path, query: query })
    }
  }
}
</script>

<template>
  <div class="flex">
    <div
      :key="filter.designator"
      v-for="filter in filters"
    >
      <div style="margin-right: 10px;">
        <p style="margin-bottom: 3px;">{{ filter.designator }}</p>
        <select
          @change="updateFilter(filter, $event.target.value)"
        >
          <option value="remove_filter">NOT SELECTED</option>
          <option
            :selected="filters_helper.isFilterOptionSelected(filter, option)"
            v-for="option in filter.options"
            :key="option.value"
            :value="option.value"
            >{{ option.text }}</option
          >
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import FiltersHelper from '@/classes/filters_helper.js'
export default {
  name: 'single-filters',
  props: ['filters'],
  data () {
    return {
      filters_helper: new FiltersHelper()
    }
  },
  methods: {
    updateFilter (filter, value) {
      let query = { ...this.$route.query }
      query[filter.designator] = value == 'remove_filter' ? undefined : value
      this.$router.push({ path: this.$route.path, query: query })
    }
  }
}
</script>

<template>
  <div class="flex">
    <div
      :key="filter.designator"
      v-for="filter in filters"
    >
      <a @click="toggleVisibility" class="button is-danger">{{visibility_text}} {{ filter.designator }} Filters</a>
      <div v-if="show_filters">
        <div class="vertical_space"></div>
        <a
          @click="toggleFilterOption(filter, option)"
          v-for="option in filter.options"
          :key="option.value"
          :class="{'is-outlined': !isOptionSelected(filter, option) }"
          class="filter button is-info"
        >
          {{ option.text }}
        </a>
        <a @click="submit(filter)" class="filter button is-danger">Submit Filters</a>
        <a @click="clearFilter(filter)" class="filter button is-danger">Clear Filters</a>
      </div>
    </div>
  </div>
</template>
<script>
import FiltersHelper from '@/classes/filters_helper.js'
export default {
  name: 'multiple-filters',
  props: ['filters'],
  data () {
    return {
      filters_helper: new FiltersHelper(),
      show_filters: true,
      selected_options_by_designator: {}
    }
  },
  created () {
    for (let i = 0; i < this.filters.length; i++) {
      let filter = this.filters[i]
      this.selected_options_by_designator[filter.designator] = (this.$route.query[filter.designator] || '').split(',')
    }
  },
  computed: {
    visibility_text () {
      return this.show_filters ? 'Hide' : 'Show'
    }
  },
  methods: {
    toggleVisibility () {
      this.show_filters = !this.show_filters
    },
    isOptionSelected (filter, option) {
      return this.selected_options_by_designator[filter.designator].includes(option.value)
    },
    getSelectedOptionsByDesignator (filter) {
      return this.selected_options_by_designator[filter.designator].filter(option_value => option_value != '')
    },
    toggleFilterOption (filter, filter_option) {
      let filter_selected_options = this.getSelectedOptionsByDesignator(filter)
      if (filter_selected_options.includes(filter_option.value)) {
        this.removeFilterOption(filter, filter_option)
        return
      }
      this.addFilterOption(filter, filter_option)
    },
    addFilterOption (filter, filter_option) {
      let filter_selected_options = this.getSelectedOptionsByDesignator(filter)
      filter_selected_options.push(filter_option.value)
      this.selected_options_by_designator[filter.designator] = filter_selected_options
      this.$forceUpdate()
    },
    removeFilterOption (filter, filter_option_to_remove) {
      let filtered_options_array = this.getSelectedOptionsByDesignator(filter).filter(filter_option_value => filter_option_value != filter_option_to_remove.value)
      this.selected_options_by_designator[filter.designator] = filtered_options_array
      this.$forceUpdate()
    },
    submit (filter) {
      let query = { ...this.$route.query }
      let selected_options = this.getSelectedOptionsByDesignator(filter).join(',')
      query[filter.designator] = selected_options.length == 0 ? undefined : selected_options
      this.$router.push({ path: this.$route.path, query: query })
    },
    clearFilter (filter) {
      this.selected_options_by_designator[filter.designator] = []
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
  .filter {
    margin-right: 10px;
    margin-top: 10px;
  }
</style>

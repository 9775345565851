<template>
	<div @click="openEditor" class="slot_status btn" :style="{ 'background-color': getStatusColorData.background, color: getStatusColorData.text }">
		<p>{{payload.status}}</p>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import SlotsHelper from '@/classes/slots_helper.js'
	export default {
		name: 'offer-status',
		props: ['payload'],
		data(){
			return {
				slots_helper: new SlotsHelper(),
			}
		},
		computed: {
			getStatusColorData(){
				if(this.slots_helper.getOrangeStatuses().includes(this.payload.status)){
					return { background: 'darkorange', text: 'white' }
				}
				if(this.slots_helper.getRedStatuses().includes(this.payload.status)){
					return { background: 'red', text: 'white' }
				}
				if(this.slots_helper.getGreenStatuses().includes(this.payload.status)){
					return { background: 'green', text: 'white' }
				}
			},
		},
		methods: {
			openEditor(){
				this.createModal({
					component_name: 'FieldForm',
					modal_title: `Edit Slot Status`,
					field_name: 'status',
					value: this.payload.status,
					resource_id: this.payload.id,
					resource_type: 'Slot',
					field_type: 'select',
					select_options: this.slots_helper.getFiltersOptions()
				})
			},
			...mapMutations('modals', ['createModal'])
		}
	}
</script>
<style scoped>
	.slot_status{
		border-radius: 30px;
		padding: 5px 10px;
		display: inline-block;
	}
</style>
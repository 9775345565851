<template>
	<div>
		<router-link :to="{ name: 'dashboard', query: { position_applicant_code: payload.position_applicant_code } }" class="stats button is-info">Stats</router-link>
	</div>
</template>
<script>
	export default {
		name: 'position-stats-link',
		props: ['payload']
	}
</script>
<style scoped>
	.stats{
		margin-top: 5px;
		font-size: 12px;
	}
</style>